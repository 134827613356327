// src/components/SpotifyButton.js
import React from 'react';
import Button from '@mui/material/Button';


const SpotifyIcon = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ color: 'white' }} // Set the color of the SVG to white
  >
    <path
      d="M12.01 2.019c-5.495 0-9.991 4.496-9.991 9.991 0 5.494 4.496 9.99 9.991 9.99 5.494 0 9.99-4.496 9.99-9.99 0-5.495-4.446-9.991-9.99-9.991zm4.595 14.436c-.199.299-.549.4-.85.201-2.349-1.45-5.296-1.75-8.793-.951-.348.102-.648-.148-.748-.449-.101-.35.149-.648.45-.749 3.795-.85 7.093-.499 9.69 1.1.35.149.4.548.251.848zm1.2-2.747c-.251.349-.7.499-1.051.249-2.697-1.646-6.792-2.148-9.939-1.148-.398.101-.85-.1-.949-.498-.101-.402.1-.852.499-.952 3.646-1.098 8.143-.548 11.239 1.351.3.149.45.648.201.998zm.099-2.799c-3.197-1.897-8.542-2.097-11.59-1.146a.938.938 0 0 1-1.148-.6.937.937 0 0 1 .599-1.151c3.547-1.049 9.392-.85 13.089 1.351.449.249.599.849.349 1.298-.25.35-.849.498-1.299.248z"
      fill="currentColor" // Use currentColor to inherit from the parent
    />
  </svg>
);

const SpotifyLinkButton = ({ onClick }) => {
  return (
    <Button startIcon={<SpotifyIcon color="white"/>} variant="contained" style={{width: "auto", height: "auto"}} onClick={onClick}>
      Link Spotify
    </Button>
  );
};

export default SpotifyLinkButton;