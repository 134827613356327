// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import LoginPage from './components/Pages/LoginPage/LoginPage';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import Error from './components/Pages/Error/Error';
import { AppBar, Tabs, Tab, Box, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutline from '@mui/icons-material/PersonOutline';

import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';



import './App.css';
import Profile from './components/Pages/Profile/Profile';
import AuthProvider from './AuthProvider';


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(false); // Set the initial value
  const [accountAnchorEl, setaccountAnchorEl] = React.useState(null);
  const accountPopupOpen = Boolean(accountAnchorEl);

  const menuItemSelected = (value) => {
    switch(value){
      case 'settings':
        break;
      case 'profile':
        navigate('/profile');
        break;
      case 'logout':
        navigate('/login');
        break;
      default:
        break;
    }
    setaccountAnchorEl(null);
  };


  useEffect(() => {
    switch (location.pathname) {
      case '/dashboard':
        setValue('dashboard');
        break;
      default:
        setValue(false); // No tab selected for other paths
        break;
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Navigate to the selected tab's route
    switch (newValue) {
      case 'dashboard':
        navigate('/dashboard');
        break;
      default:
        navigate('/error');
        break;
    }
  };

  return (
    <div className="App">{ value &&
      (<AppBar position="static" style={{ display: 'flex', flexDirection: 'row', paddingLeft: "5px", paddingRight: "15px" }} >
        <Tabs sx={{ flexGrow: 1 }} value={value} textColor="inherit" indicatorColor="secondary" onChange={handleChange}>
          <Tab label="Dashboard" value="dashboard" />
        </Tabs>
        <IconButton
          id="account-button"
          aria-controls={accountPopupOpen ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={accountPopupOpen ? 'true' : undefined}
          onClick={(event) => {
            setaccountAnchorEl(event.currentTarget);
          }}
          style={{ padding: "0px" }}>
          <PersonIcon sx={{ color: 'white', width: "35px", height: "35px" }} />
        </IconButton>
        <Menu
          id="account-menu"
          anchorEl={accountAnchorEl}
          open={accountPopupOpen}
          onClose={(value) => { setaccountAnchorEl(null) }}
          MenuListProps={{
            'aria-labelledby': 'account-button',
          }}
        >
          <MenuItem onClick={() => { menuItemSelected("profile") }}>
            <ListItemIcon>
              <PersonOutline fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={() => { menuItemSelected("settings") }}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={() => { menuItemSelected("logout") }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>) }
      <Box>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </Box>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <AuthProvider>
      </AuthProvider>
      <App />
    </Router>
  );
}
export default AppWithRouter;
