import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import catImage from '../../../assets/keddy_standing.png'
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import {
  getSpotifyUserProperties
} from '../../../services/Auth';
function Dashboard() {
  const navigate = useNavigate();
  const[spotifyLinked, setSpotifyLinked] = useState(false);

  useEffect(() => {
    getSpotifyUserProperties().then((data) => {
        if(data == null) navigate("/profile");
        else setSpotifyLinked(true);
    });
}, [navigate]);

  return spotifyLinked && (
    <div className='dashboard__dashboard-container'>
      <div className='dashboard__content'>
        <img
          src={catImage}
          alt="Cat"
          className="dashboard__cat-image"
        />
        <Typography variant='h3' gutterBottom>Keddy appreciates your support.</Typography>
        <Typography fontSize={15} variant='h3'>He also now has access to your Spotify account, so be polite to him.</Typography>
      </div>

    </div>
  );
}

export default Dashboard;