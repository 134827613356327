import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useNavigationListener = (eventName, targetPath) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleNavigation = () => {
      navigate(targetPath);
    };

    window.addEventListener(eventName, handleNavigation);

    return () => {
      window.removeEventListener(eventName, handleNavigation);
    };
  }, [navigate, eventName, targetPath]);
};

export default useNavigationListener;