import React from 'react';
import useNavigationListener from './hooks/useNavigationListener';

function AuthProvider() {
    useNavigationListener('navigateToLogin', '/login');

  return (<></>
  );
}

export default AuthProvider;