import React, { useEffect, useState } from 'react';
import './Profile.css';
import KeddyHeader from '../../KeddyHeader/KeddyHeader'
import { Typography } from '@mui/material';
import { getSpotifyRegisterURI, getSpotifyUserProperties } from '../../../services/Auth'
import SpotifyLinkButton from '../../SpotifyLinkButton/SpotifyLinkButton'
import { useNavigate } from 'react-router-dom';

function Profile() {
    const [spotifyURI, setSpotifyURI] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        getSpotifyUserProperties().then((data) => {
            if(data == null){
                getSpotifyRegisterURI().then((data) => {
                    setSpotifyURI(data);
                });
            } else{
                navigate("/");
            } 
        });

    }, [navigate]);

    const onSpotifyLinkClicked = () => {
        if (spotifyURI)
            window.location.href = spotifyURI;
    };

    return (spotifyURI != null) && (
        <div className='profile__complete-profile-container'>
            <div className='profile__content'>
                <div style={{ marginBottom: 35 }}>
                    <KeddyHeader />
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant='h4'>Help this Keddy expand his music repertoire by linking your Spotify account:</Typography>
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 35 }}>
                    <SpotifyLinkButton onClick={onSpotifyLinkClicked}></SpotifyLinkButton>
                </div>
            </div>
        </div>
    );
}

export default Profile;