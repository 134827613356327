import React from 'react';
import './KeddyHeader.css';
import catImage from '../../assets/keddy_head_upscale_circle.png'
import { Typography } from '@mui/material';


function KeddyHeader() {
    return (
        <div>
            <img
                src={catImage}
                alt="Cat"
                className="keddyheader__cat-image"
            />
            <Typography variant="h2" component="h2">
                Keddy
            </Typography>
        </div>
    );
}

export default KeddyHeader;